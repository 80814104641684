import { getWebInstrumentations, initializeFaro } from "@grafana/faro-web-sdk";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";

if (typeof window !== "undefined") {
  initializeFaro({
    url: "https://faro-collector-prod-eu-west-0.grafana.net/collect/ad929379f2c8e5481f2ee153eeca4f88",
    app: {
      name: "origami",
      version: "1.0.0",
      environment: process.env.SENTRY_ENVIRONMENT
    },
    sessionTracking: {
      sampler() {
        if (process.env.NODE_ENV == "development") return 1;
        return 0.1;
      }
    },
    batching: {
      sendTimeout: 20000
    },
    ignoreErrors: [
      // These events occur very frequently, and there is not much we can do about it
      /Warning:.*/,
      /.*Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type.*/,
      /.*ResizeObserver loop limit exceeded.*/,
      /.*AbortError: The play() request was interrupted by a call to pause().*/,
      /.*NotSupportedError: The element has no supported sources.*/
    ],
    ignoreUrls: [
      /.*_next.*/,
      /.*mp.papercup.com.*/,
      /.*flagsmith.papercup.com.*/,
      /.*auth0.papercup.com.*/
    ],
    instrumentations: [
      // Mandatory, omits default instrumentations otherwise.
      ...getWebInstrumentations(),

      // Tracing package to get end-to-end visibility for HTTP requests.
      // Mandatory, initialization of the tracing package
      new TracingInstrumentation({
        instrumentationOptions: {
          // Requests to these URLs have tracing headers attached.
          propagateTraceHeaderCorsUrls: [
            new RegExp("https://(.*)?papercup.com/.*")
          ]
        }
      })
    ]
  });
}
